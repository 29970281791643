import React from 'react'
import videoBg from '../assets/bake2.mp4';


const Main = () => {
	return (
		<div className='main'>
{/*<video src={videoBg} autoPlay={true} loop={true} controls={false} playsInline muted/>*/}
		</div>
	)
}

export default Main