import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './Components/Navbar';
import Header from "./Components/Header";
import Products from './Components/Products';
import About from './Components/About';
/*import Contact from './Components/Contact';*/
import Main from './Components/Main';
import Footer from './Components/Footer';
import ContactUs from './Components/ContactUs';



import { css } from "@emotion/react";
// import PropagateLoader from 'react-spinners/PropagateLoader';
// import { RotateLoader } from 'react-spinners';
// import { PropagateLoader } from 'react-spinners';
import { RotateLoader } from "react-spinners";

function App() {

    const [loading, setLoading] = useState(false);
    const override = css`
    display:block;
    border-color:red;
    margin-top:20%;
    `;

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 2000);
    },[])

    return (
      <div className="App">
        {loading ? (
          /* <PropagateLoader
            color={"#575757"}
            Loading={loading}
            css={override}
            size={40}
          /> */

          /* <RotateLoader className='rotate' color="#36d7b7" /> */

          <RotateLoader
            color="#36d7b7"
            cssOverride={{
              position: "fixed",
              top: "30%",
              left: "50%",
            }}
            loading
            size={100}
            speedMultiplier={1}
          />
        ) : (
          <>
            <Navbar />
            <Main />
            <Header />
            <Products />
            <About />
            <ContactUs />
           <Footer />
          </>
        )}
      </div>
    );
}

export default App;