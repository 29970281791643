import React from 'react';
import Productbox from './Productbox';
import pimage2 from '../images/CAKEPOPOFICIAL.jpg';
import pimage3 from '../images/TRESLECHES.jpg';
import pimage4 from '../images/FLANOFICIAL.jpg';
import pimage5 from '../images/dcocoa.jpg';
import pimage6 from '../images/brownies.jpg';
import pimage7 from '../images/tiramisu.jpg';
import pimage8 from '../images/cakeimpossible.jpg';
import pimage9 from '../images/ricepudding.jpg';
import pimage10 from '../images/bridal.jpg';
import pimage11 from '../images/customcakes.jpg';

function Products() {
	return (
		<div id='products'>
			<h1>OUR PRODUCTS</h1>
			<p>We have a variety of sweets, made with the Ecuadorian flavor of our prestigious Pastry Chef Cristina Andrade</p>
			<div className='a-container'>
				<Productbox image={pimage8} title="Cake Impossible"/>
				<Productbox image={pimage6} title="Brownies"/>
				<Productbox image={pimage4} title="Cream Cheese Flan"/>
				<Productbox image={pimage2} title="Cake Pops"/>
				<Productbox image={pimage3} title="Three Cake Milks"/>
				<Productbox image={pimage5} title="Cocoa Bombs"/>
				<Productbox image={pimage7} title="Tiramisu"/>
				<Productbox image={pimage9} title="Rice Pudding"/>
				<Productbox image={pimage10} title="Bridal Cake"/>
				<Productbox image={pimage11} title="Custom Cakes"/>
			</div>
		</div>
	)
}

export default Products;
