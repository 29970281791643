import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import swal from 'sweetalert';

export const ContactUs = () => {
  const form = useRef();

  const mostrarAlerta=()=>{
    swal ({
      title:"Message",
      text:"You message was send",
      icon:"success",
      button:"Aceptar"
    });
  }
  

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_cher122', 'template_ydytsgn', form.current, 'rwH9Gs7LhspOAGc6v')
      .then((result) => {
          console.log(result.text);
          form.current.reset(); 
      }, (error) => {
          console.log(error.text);
      });
  };
  
  


  return (
    <div id="contact">
      <h1>Contact Us</h1>
      <form ref={form} onSubmit={sendEmail}>
        <label>
          <font color="white">Name</font>
        </label>
        <input type="text" name="user_name" />
        <label>
          <font color="white">Email</font>
        </label>
        <input type="email" name="user_email" />
        <label>
          <font color="white">Message</font>{" "}
        </label>
        <textarea name="message" />
        <input type="submit" value="Send" onClick={() => mostrarAlerta()} />
      </form>
    </div>
  );
};
export default ContactUs;


	