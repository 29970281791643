import React from 'react';
import aboutimage from '../images/abo.png';

function About() {
	return (
		<div id='about'>
			<div className="about-text">
				<h1>WHAT WE DO</h1>
			<p>Wedding, Corporate Events. Birthday, Retirement , Gender Reveal Parties. Family Reunions. Bridal , Baby Showers. Engagement Celebrations, Holiday Festivities, Catering Services and much more. 
Bring your ideas and we will work with you for any occassion.</p>
			<button>Read More</button>
			</div>
			<div className="about-image">
				<img src={aboutimage} alt="" />
			</div>

		</div>
	)
}

export default About;