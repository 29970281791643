import React from 'react';


function Header() {
    return ( 
				<div id = 'main'>
        <div className = 'header-heading' >

        <h3> THE BEST DESSERTS MADE BY </h3> 
				<h1 > <span> SWEET </span> ECUADOR<br/> </h1> <p className = 'details'>{/*  comentario debajo de sweet ecuador You can find our product catalog here.*/} </p> 
				<div className = 'Header-btns'>
       {/* aqui es para habilitar el boton <a href = "#" className = "header-btn" > Clic Here </a>*/}

        </div>				 </div >

        </div>
    )
}

export default Header;